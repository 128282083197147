.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #eee;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tip {
  position: fixed;
  right: 0px;
  margin-right: 15px;
  background: #eee;
  padding: 20px;
  justify-content: end;
  box-shadow: 5px 5px 10px gray;
  border-radius: 5px;
  max-height: 85%;

}

.desktop {
  display: none;
  top: 10vh;
  width: 20%;
  min-width: 200px;
}

.mobile {
  display: none;
  max-height: 85dvh;
  top: 60px;
  width: 100%;
}

@media (min-width: 576px) {
  .desktop {
    display: block;
  }
}

@media (max-width: 576px) {
  .mobile {
    display: block;
  }
}
